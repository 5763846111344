//@flow
import { BadgeOutlined, Collections, ForwardToInbox } from "@mui/icons-material";
import ListAltIcon from '@mui/icons-material/ListAlt';
import { ListItem } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import AlertForCatalogLock from "../../../../../components/app/alertForCatalogLock/alertForCatalogLock";
import { TabPanel } from "../../../../../components/general/tabPanel/tabPanel";
import { getRouteUrl } from "../../../../../helpers/getRouteUrl";
import { normalizeGreek } from "../../../../../helpers/normalizeGreek";
import useExhibitionData from "../../../../../hooks/exhibitions/useExhibitionData";
import { useExhibitor } from "../../../../../hooks/exhibitions/useExhibitor";
import useQueryParams from "../../../../../hooks/useQueryParams";
import { EXHIBITOR_PROFILE } from "../../../../../locales/components/namespaces";
import { ROUTE_PAGE_EXHIBITION } from "../../../../../routers/routes";
import FaqsView from "../../../../faqs/faqsView";
import { TitleContext } from "../../../../main/content";
import ExhibitorProfile from "../../../../profileExhibitor/profileExhibitor";
import Details from "../../common/details/details";

const Main = () => {
	const { t } = useTranslation(EXHIBITOR_PROFILE)
	const queryParams = useQueryParams()
	const section = queryParams.get('section') ?? ""
	const { id } = useParams()
	const { byId: exhibition, loading } = useExhibitionData({ id })
	const { setMenuNavigation } = useContext(TitleContext)
	const { exhibitor } = useExhibitor({
		language: 'any',
		overrideExhibitorId: 'currentUser',
		section: ""
	})
	
	const links = useMemo(() => {
		const customExhibitorButtonStyles = {
			width: '100%',
			backgroundColor: '#FFFFFF',
			whiteSpace: "break-spaces",
			borderLeft: `6px solid ${exhibition.buttonColor}`,
			paddingLeft: '8px',
			paddingRight: '8px',
			justifyContent: 'space-between',
			'& .MuiButton-endIcon': {
				color: exhibition.buttonColor
			},
			'&:hover': {
				backgroundColor: '#FFFFFF',
				boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.1) inset'
			},
		}
		
		const customExhibitorButtonIconStyles = { fontSize: "1.8rem", paddingRight: "0.2rem", width: "1.8rem" }
		
		return (
			<List dense>
				<ListItem disableGutters>
					<Typography
						sx={{ width: '100%', textAlign: 'center' }}
						variant={'button'}
						component={'div'}
					>
						{normalizeGreek(t('Main Actions'))}
					</Typography>
				</ListItem>
				<ListItem>
					<Button
						variant="contained"
						sx={customExhibitorButtonStyles}
						endIcon={<ListAltIcon style={customExhibitorButtonIconStyles}/>}
						component={Link}
						to={getRouteUrl(ROUTE_PAGE_EXHIBITION, { id: id }, {
							section: "profile",
							subSection: "catalog"
						})}
					>
						{normalizeGreek(t("Catalogue"))}
					</Button>
				</ListItem>
				{exhibition.displayVisitorsToExhibitor &&
					<ListItem>
						<Button
							variant="contained"
							sx={customExhibitorButtonStyles}
							endIcon={<ForwardToInbox style={customExhibitorButtonIconStyles}/>}
							component={Link}
							to={getRouteUrl(ROUTE_PAGE_EXHIBITION, { id: id }, {
								section: "profile",
								subSection: "visitors"
							})}
						>
							{normalizeGreek(t("Invitation"))}
						</Button>
					</ListItem>
				}
				{exhibition.displayExhibitorKitAndAddsToExhibitor && (
					<ListItem>
						<Button
							variant="contained"
							sx={customExhibitorButtonStyles}
							endIcon={<Collections style={customExhibitorButtonIconStyles}/>}
							component={Link}
							to={getRouteUrl(ROUTE_PAGE_EXHIBITION, { id: id }, {
								section: "profile",
								subSection: "kit"
							})}
						>
							<span>{normalizeGreek(t("Marketing Kit - Upload Logo"))}
								<sup
									style={{
										color: '#d32f2f',
										paddingLeft: '4px',
										fontWeight: 'bold'
									}}
								>
									{t('NEW')}
								</sup>
							</span>
						</Button>
					</ListItem>
				)}
				{exhibition.displayPartnersToExhibitor && (
					<ListItem>
						<Button
							variant="contained"
							sx={customExhibitorButtonStyles}
							endIcon={<BadgeOutlined style={customExhibitorButtonIconStyles}/>}
							component={Link}
							to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {
								id: id,
							}, { section: "profile" })}
						>
							{normalizeGreek(t("Entry Cards (Badges) - Exhibitors"))}
						</Button>
					</ListItem>
				)}
				{exhibition.displayServiceProvidersToExhibitor && (
					<ListItem>
						<Button
							variant="contained"
							sx={customExhibitorButtonStyles}
							endIcon={<BadgeOutlined style={customExhibitorButtonIconStyles}/>}
							component={Link}
							to={getRouteUrl(ROUTE_PAGE_EXHIBITION, {
								id: id,
							}, { section: "profile", subSection: "serviceProviders" })}
						>
							{normalizeGreek(t("Entry Cards (Badges) - Service Providers"))}
						</Button>
					</ListItem>
				)}
			</List>
		)
	}, [exhibition.displayExhibitorKitAndAddsToExhibitor, exhibition.displayPartnersToExhibitor, exhibition.displayServiceProvidersToExhibitor, exhibition.displayVisitorsToExhibitor, id, t])
	
	useEffect(() => {
		setMenuNavigation(
			<React.Fragment>
				{links}
				<Divider/>
				<FaqsView
					exhibitionId={id}
				/>
			</React.Fragment>
		)
		return () => {
			setMenuNavigation(null)
		}
	}, [id, links, setMenuNavigation, exhibition.buttonColor])
	
	return (
		<Box>
			{exhibitor && exhibition && !loading && (
				<AlertForCatalogLock
					exhibition={exhibition}
					exhibitor={exhibitor}
				/>
			)}
			<TabPanel isVisible={section === ""} sx={{ pt: 2 }}>
				<Details
					exhibition={exhibition}
					loading={loading}
				/>
			</TabPanel>
			<TabPanel isVisible={section === "profile"}>
				<Box sx={{ my: 1.5 }}/>
				<ExhibitorProfile
					id={"currentUser"}
				/>
			</TabPanel>
		</Box>
	)
}

export default Main